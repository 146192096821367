/**---------  DASHBOARD  ---------------------*/

import {type_json} from './utils'

export default Api => ({
  /**
   * Dashboard tabs
   * @returns {Object} dashboard-tabs
   * [{
   *     "id": 1,
   *     "name": "translate.dashboard-1"
   *   },
   *   {
   *     "id": 2,
   *     "name": "translate.dashboard-2"
   *   },
   *   {
   *     "id": 3,
   *     "name": "translate.dashboard-3"
   *   }]
   */
  load_tabs(domain) {
    return Api.get(`/dashboard/tabs`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Dashboard tab data
   * @returns {Object} dashboard data
   */
  load_tab_data(domain, id) {
    return Api.get(`/dashboard/tabs/${id}`, {
      headers: {
        ...type_json
      }
    })
  }
})