/**---------  DISASTERS  ---------------------*/

import {type_json, type_multipart_form} from './utils'

export default Api => ({
  /**
   * Disaster list
   * @returns Array<{Object}>
   */
  load_list() {
    return Api.get(`/disasters/`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Disaster by id
   * @param id {integer} disaster ID
   * @returns {Object}
   */
  load_disaster_by_id(id) {
    return Api.get(`/disasters/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Import Disasters by csv file
   * @param form {object} form
   * @returns {}
   */
  import_data(form) {
    return Api.post(`/disasters/import`, form, {
      headers: {
        ...type_multipart_form
      }
    })
  }
})