const Features = {
    disasters:'disasters',
    unitCostWorkCriterion:'unit-cost-work-criterion',
    plans:'plans',
    planConsolidation:'plan-consolidation',
    bridgeInterventions:'bridge-interventions',
    unitCost:'unit-cost',
    assetCollection:'asset-collection',
    permissions:'permissions',
    bridgeInventory:'bridge-inventory',
    bridgeCondition:'bridge-condition',
    inspectionFiles:'inspection-files',
    users:'users',
    bridge:'bridge',
    features:'features',
    unitCostWork:'unit-cost-work',
    localisation:'localisation',
    subnetwork:'subnetwork'
}

const Actions = {
    create:'create',
    structs:'structs',
    worksCalc:'works-calc',
    import:'import',
    update:'update',
    list:'list',
    setPassword:'set-password',
    delete:'delete',
    actionsList:'actions-list',
    selectedUpdate:'selected-update'
}
export {Features, Actions}