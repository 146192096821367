// const br_lang = (
//   navigator.browserLanguage ||
//   navigator.language ||
//   navigator.userLanguage ||
//   'en'
// ).substring(0, 2)

const user_lang = localStorage.getItem('bms-locale')

const locales = [
  {
    code: 'en',
    name: 'English'
  }
]
const defaultLocale = user_lang || locales[0].code

export {locales, defaultLocale}
