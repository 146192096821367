/**
 * ------------ REPORTS -------------------
 */

import {type_json} from '@/api/utils'

export default Api => ({
  /**
   * list of report types
   * @return {Promise<*> Array}
   * @returns {Array}
   * @return {Object}
   * @return {String} key,
   * @return {String} value
   * @throws Error
   */
  load_list(domain) {
    return Api.get(`${domain}/reports/list/`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * load report data
   * @param {String} type
   * @return {Promise<*> [data]}
   * @return {Array}
   * @throws Error
   */
  load_data(domain, type) {
    return Api.get(`${domain}/reports/data/${type}`, {
      headers: {
        ...type_json
      }
    })
  }
})