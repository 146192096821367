/*--------  USERS ---------------*/

import {type_json} from '@/api/utils'

export default Api => ({
    /**
     * User role profile data
     * @returns {Object} user role properties
     * {
     * @returns "menu_items[]": "menu_items:[]",
     * @returns "name": "name":"menu.dashboard",
     * @returns "icon": "icon":"dashboard",
     * @returns "path": "path":"/dashboard",
     * }
     */

    load_profile() {
        return Api.get(`/me`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * User list
     * @returns {Object} user list
     * {
     * }
     */
    load_users() {
        return Api.get(`/users`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Add a new user
     * @param data {Object}- user params
     * @returns null
     * @type {(data:{})=>void}
     */
    add(data) {
        return Api.post(`/users`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Update a user
     * @param login {string} - login
     * @param data {Object}- user params
     * @returns null
     * @type {(login: string, data:{}) => void}
     */
    update(login, data) {
        return Api.put(`/users/${login}`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Delete a user
     * @param login {string} - login
     * @returns null
     * @type {(login: string)}
     */
    delete(login) {
        return Api.delete(`/users/${login}`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Loads User props
     * @returns {Object} user props
     * {
     * }
     */
    load_user_by_login(login) {
        return Api.get(`/users/${login}`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Set User password
     */
    set_password(payload) {
        const {login, password} = payload
        return Api.put(`/users/${login}/set-password`, {password}, {
            headers: {
                ...type_json
            }
        })
    }
})