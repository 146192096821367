import api from '@/api'

export default {
  state: {},
  actions: {
    async LOAD_DISASTER_LIST() {
      try {
        const res = await api.disasters.load_list()
        const {status, data} = res
        if (status === 200) {
          return data
        } else {
          throw `Load disaster list error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async LOAD_DISASTER_BY_ID(context, id) {
      try {
        const res = await api.disasters.load_disaster_by_id(id)
        const {status, data} = res
        if (status === 200) {
          return data
        } else {
          throw `Load disaster by id error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async DISASTERS_IMPORT(context, form) {
      try {
        const res = await api.disasters.import_data(form)
        const {status, headers} = res
        if (status === 201) {
          return headers.location
        } else {
          throw `Import disasters error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    }
  }
}