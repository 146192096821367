/**---------  SUBNETWORK -----------------*/

import {type_json} from '@/api/utils'

export default Api => ({

  /**
   * Get Subnetwork list
   * @return Promise<Array<string>>
   */
  async load_subnetwork_list() {
    const params = {}
   return Api.get(`/plans/subnetworks`, {
      params,
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get Subnetwork by id
   * @return Promise<Array<string>>
   */
  async load_subnetwork_by_id(id) {
    return Api.get(`/plans/subnetworks/${id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Add Subnetwork item
   * @param {Object} {
   * @param {string} subnetwork_name
   * @param {Array} subnetwork_items
   * @param {string} comments}
   * @return Promise<>
   */
  async add_subnetwork_item(data) {
    return Api.post(`/plans/subnetworks/add`, data, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Update Plan item
   * @param {Object} {
   * @param {string} subnetwork_id
   * @param {string} subnetwork_name
   * @param {Array} subnetwork_items
   * @param {string} comments}
   * @return Promise<>
   */
  async upd_subnetwork_item(id, data) {
    return Api.post(`/plans/subnetworks/upd/${id}`, data, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Load Roadcode items
   * @param {Array} {
   * @param {string} name
   * @return Promise<>
   */
  async load_roadcodes_list() {
    return Api.get(`/plans/roadcodes`, {
      headers: {
        ...type_json
      }
    })
  }
})