import api from '@/api'

export default {
  state: {report_data: {headers: [], rows: []}},
  actions: {
    async LOAD_REPORTS_LIST(context, domain) {
      try {
        const res = await api.reports.load_list(domain)
        const {status, data} = res
        if (status === 200) {
          return data
        } else {
          throw `Load list of reports status: ${status}`
        }
      } catch (err) {
        throw `Load reports list: ${err}`
      }
    },
    async LOAD_REPORT_DATA({commit}, {type, domain}) {
      try {
        commit('SET_REPORT_DATA', {rows:[], headers: []})
        const res = await api.reports.load_data(domain, type)
        const {status, data} = res
        if (status === 200) {
          commit('SET_REPORT_DATA', data)
        } else {
          throw `Load report data status: ${status}`
        }
      } catch (err) {
        throw `Load reports: ${err}`
      }
    }
  },
  mutations: {
    SET_REPORT_DATA(state, report_data) {
      state.report_data = {...report_data}
    }
  },
  getters: {}
}
