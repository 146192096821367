import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store/'
import DashboardPlugin from './material-dashboard'

// Plugins
import App from './App.vue'
import Chartist from 'chartist'

// translate
import i18n from './i18n'

// router setup
import routes from './routes'
import multiguard from '@/routes/middleware/multiguard'

// plugin setup
Vue.use(VueRouter)
Vue.use(DashboardPlugin)

Vue.prototype.$appName = 'rms'

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for index: index
  scrollBehavior: (/*to*/) => {
    //if (to.hash) {
    //  return {selector: to.hash}
    //} else {
    return {x: 0, y: 0}
    //}
  },
  linkExactActiveClass: 'nav-item active',
  linkActiveClass: 'nav-item active'
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return multiguard(middleware, context)
})

// global library setup
Object.defineProperty(Vue.prototype, '$Chartist', {
  get() {
    return this.$root.Chartist
  }
})

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    // Show any error but this one
    if (err.message !== "Cannot read property 'badInput' of undefined") {
      console.error(err)
    }
  }
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  data: {
    Chartist: Chartist
  },
  render: (h) => h(App)
})
