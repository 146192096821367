/**
 * ------------ PERMISSIONS -------------------
 */

import {type_json} from '@/api/utils'

export default Api => ({
  /**
   * Roles list
   * @returns {Promise<AxiosResponse<any>>} user role properties
   * [
   * @returns {Object}
   *  {
   *    @returns {number} ID
   *    @returns {string} role_name,
   *    @returns {string} description
   * }
   * ]
   */
  load_roles() {
    return Api.get(`/roles`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Role users
   * @returns {Promise<AxiosResponse<any>>} user role properties
   * [
   * @returns {Object}
   *  {
   *    @returns {string}  login,
   *    @returns {string} email
   *    @returns {string} first_name
   *    @returns {string} last_name
   * }
   * ]
   */
  load_role_users(name) {
    return Api.get(`/roles/${name}/users`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Permissions list
   *
   *
   * @returns {Promise<AxiosResponse<any>>} users permissions
   * [
   * @returns {Array}
   *  [
   *    @returns {Object}
   *    {
   *      @returns {string} sub
   *      @returns {string} feature
   *      @returns {string} action
   *    }
   *  ]
   * ]
   */
  load_list() {
    return Api.get(`/permissions`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Grant permission
   *
   * @param {object} permisson
   * @param {string} sub role name
   * @param {string} feature feature name


   * @param {string} action action name
   * @returns {Promise<AxiosResponse<any>>} 200
   */
  grant(data) {
    return Api.post(`/permissions/grant`, data, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Revoke permission
   *
   * @param {object} permisson
   * @param {string} sub role name
   * @param {string} feature feature name
   * @param {string} action action name

   * @returns {Promise<AxiosResponse<any>>} 200
   */
  revoke(data) {
    return Api.post(`/permissions/revoke`, data, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Features structs list
   * @returns {Promise<AxiosResponse<any>>} features
   * @returns {Array}
   * [
   *    @returns {string} feature name
   *    @returns {Array} feature actions
   *    @returns {string} action name
   * ]
   *
   */
  load_features_structs() {
    return Api.get(`/features/structs`, {
      headers: {
        ...type_json
      }
    })
  }
})