/** ------------------ MUC ---------------*/
import {type_json} from '@/api/utils'

export default Api => ({

  /**
   * Get system MUC list
   * @returns {Array} MUCs :
   * @returns {Object} [
   * @returns {number} {id
   * @returns {string} , name
   * @returns {string} , category
   * @returns {string} , type
   * @returns {number} , year
   * @returns {Array}  , works}]
   *
   */
  async load_unit_costs_list() {
    return Api.get(`/unit-costs`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get MUC table list
   * @returns {Array} MUCs :
   * @returns {Object} [
   * @returns {number} {id}]
   *
   */
  async load_unit_costs_table_list() {
    return Api.get(`/unit-costs`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get MUC by id
   * @param {number} mid
   * @returns {Object}
   * @returns {number} {id
   * @returns {string} , name
   * @returns {string} , category
   * @returns {string} , type
   * @returns {number} , year
   * @returns {Array}  , works[]}
   *
   */
  async get_unit_costs_by_id(id) {
    return Api.get(`/unit-costs/${id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Add MUC item
   * @param {Object} unit costs item
   * @param {string} name
   * @param {string} type
   * @param {number} year
   *
   */
  async add_unit_costs_item(data) {
    return Api.post(`/unit-costs/add`, data, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Upd MUC item
   * @param {Object} unit costs item
   * @param {string} mid
   * @param {string} name
   * @param {string} asset_type
   * @param {number} year
   *
   */
  async upd_unit_costs_item(mid, data) {
    return Api.post(`/unit-costs/upd/${mid}`, data, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get system MUC item work list
   * @returns {Array} MUC works :
   * @returns {Object} [
   * @returns {number} id
   * @returns {string} name
   * @returns {string} unit
   * @returns {string} fin_cost
   * @returns {number} econom_cost
   * @returns }]
   *
   */
  async load_unit_costs_works(mid) {
    return Api.get(`/unit-costs/${mid}/works`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get MUC work item by id
   * @param {string} muc_id
   * @param {number} work_id
   * @returns {Object}
   * @returns {number} {id
   * @returns {string} name
   * @returns {string} unit
   * @returns {string} fin_cost
   * @returns {number} econom_cost}
   */
  async get_unit_costs_work_by_id(muc_id, work_id) {
    return Api.get(`/unit-costs/${muc_id}/works/${work_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Add MUC work item
   * @param {string} muc_id
   * @param {string} work_name
   * @param {string} work_unit
   * @param {number} work_fin_cost
   * @param {number} work_econom_cost
   * @param {number} work_maintenance_type
   * @param {number} work_asset_type
   * @param {string} comments
   *
   */
  async add_unit_costs_work(muc_id, data) {
    return Api.post(`/unit-costs/${muc_id}/works/add`, data, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Upd MUC work item
   * @param {string} muc_id
   * @param {number} work_id
   * @param {string} work_name
   * @param {string} work_unit
   * @param {number} work_fin_cost
   * @param {number} work_econom_cost
   * @param {number} work_maintenance_type
   * @param {number} work_asset_type
   * @param {string} comments
   */
  async upd_unit_costs_work(work_id, muc_id, data) {
    return Api.post(`/unit-costs/${muc_id}/works/upd/${work_id}`, data, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get MUC work criteria
   * @param {string} muc_id
   * @param {number} work_id
   * @returns {Promise} Promise object
   * @returns {Array}
   * @returns {Object}
   * @returns {number} {var,
   * @returns {string} condition,
   * @returns {string} val}
   */
  async get_unit_costs_work_criteria(muc_id, work_id) {
    return Api.get(`/unit-costs/${muc_id}/works/${work_id}/criteria`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get MUC work criterion item by id
   * @param {string} muc_id
   * @param {number} work_id
   * @param {number} crit_id
   * @returns {Array}
   * @returns {Object}
   * @returns {number} {var
   * @returns {string} condition
   * @returns {string} val}
   */
  async get_unit_costs_work_criterion_by_id(crit_id, work_id, muc_id) {
    return Api.get(
      `/unit-costs/${muc_id}/works/${work_id}/criteria/${crit_id}`,
      {
        headers: {
          ...type_json
        }
      }
    )
  },

  /**
   * Add MUC work criterion item
   * @param {string} muc_id
   * @param {string} work_id
   * @param {string} criterion_variable
   * @param {string} criterion_condition
   * @param {Array} criterion_value
   * @return Promise<>
   */
  async add_unit_costs_work_criterion(work_id, muc_id, data) {
    return Api.post(
      `/unit-costs/${muc_id}/works/${work_id}/criteria/add`,
      data,
      {
        headers: {
          ...type_json
        }
      }
    )
  },

  /**
   * Upd MUC work criterion item
   @param {string} muc_id
   @param {number} work_id
   @param {number} work_id
   @param {string} criterion_variable
   @param {string} criterion_condition
   @param {Array} criterion_value
   @returns {Promise}
   */
  async upd_unit_costs_work_criterion(crit_id, work_id, muc_id, data) {
    return Api.post(
      `/unit-costs/${muc_id}/works/${work_id}/criteria/upd/${crit_id}`,
      data,
      {
        headers: {
          ...type_json
        }
      }
    )
  },

  /**
   * Get Asset type list
   * @returns {Promise<Array<string>>}
   */
  async load_asset_type_list(category) {
    const params = {}

    if (category) {
      params.category = category
    }
    return Api.get(`/plans/asset-types`, {
      params,
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Get Asset type list
   * @returns {Promise<Array<string>>}
   */
  async load_maintenance_type_list(category = undefined) {
    const params = {}

    if (category) {
      params.category = category
    }

    return Api.get(`/plans/maintenance-types`, {
      params,
      headers: {
        ...type_json
      }
    })
  },

})