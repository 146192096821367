import api from '@/api'


const TOKEN_NAME = 'bms-token'
const client_id = 'rams'

export default {
    state: {
        token: localStorage.getItem(TOKEN_NAME) || '',
        authStatus: '',
        me: {},
        pathAfterLogin: '',
        appOptions: {},
        timer: undefined,
        jwtRefreshTime: 600000
    },
    actions: {
        async REFRESH_JWT({commit}) {
            console.log('RefresherAct', new Date().getMinutes())
            try {
                const resp = await api.auth.refreshJwt({client_id})
                const {
                    status: code,
                    data: {access_token: access_token}
                } = resp
                if (code === 200 && access_token) {
                    localStorage.setItem(TOKEN_NAME, access_token)
                    commit('AUTH_SUCCESS', access_token)
                    commit('SET_HEADER_AUTH')
                }
            } catch (err) {
                console.log(err)
            }
        },
        // async START_JWT_REFRESHER({ dispatch, commit, state }) {
        //   if (state.timer) {
        //     return
        //     //return commit('STOP_JWT_REFRESHER', state.timer)
        //   }
        //   const timerId = setInterval(() => {
        //     dispatch('REFRESH_JWT')
        //   }, state.jwtRefreshTime)
        //   dispatch('REFRESH_JWT')
        //   commit('SET_JWT_REFRESHER', timerId)
        // },
        // Login is a user login function
        async LOGIN({commit}, formData) {
            commit('AUTH_REQUEST')
            // TODO Replace temporary password for real
            const {login, password} = formData

            try {
                const resp = await api.auth.login({login, password})
                const {
                    status: code,
                    data: {token: token}
                } = resp

                if (code === 200 && token) {
                    localStorage.setItem(TOKEN_NAME, token)
                    commit('AUTH_SUCCESS', token)
                    commit('SET_HEADER_AUTH')
                } else {
                    throw 'Authorization error'
                }
            } catch (err) {
                console.log(err)
                commit('AUTH_ERROR')
                //if the request fails, remove any possible user token if possible
                localStorage.removeItem(TOKEN_NAME)
            }
        },
        async LOGOUT({commit}) {
            localStorage.removeItem(TOKEN_NAME)
            //await api.logout()
            api.delHeaderAuth()
            commit('AUTH_LOGOUT')
        },
        // GET_MY_PROFILE is a function to get login user properties
        async GET_MY_PROFILE({commit, dispatch}) {
            try {
                const user = await api.users.load_profile()
                const {name, full_name, role, menu_items, permissions} = user.data
                commit('SET_USER', {name, full_name, role, menu_items, permissions})
            } catch (e) {
                dispatch('LOGOUT')
                throw `Getting profile error: ${e}`
            }
        },
        // LOAD_APP_OPTIONS loads default application options
        async LOAD_APP_OPTIONS({commit}) {
            try {
                const res = await api.load_app_options()

                commit('SET_APP_OPTIONS', {
                    // invent_url: defInventUrl,
                    // invent_profile: defInventProfile,
                    // invent_user_management: defInventUserManagement,
                    // invent_group_management: defInventGroupManagement,
                    ...res.data
                })
            } catch (e) {
                throw `Load app options: ${e}`
            }
        },
        // LOAD_APP_OPTIONS loads default application options
        async LOAD_APP_UNIT_TYPES() {
            try {
                const res = await api.load_app_unit_types()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `error load app unit types, status: ${status}`
                }

            } catch (e) {
                throw `Load app options: ${e}`
            }
        },

        // SET_MY_PASSWORD sets password for user
        async SET_MY_PASSWORD(context, password) {
            try {
                const {status, data, response} = await api.auth.set_my_password(password)
                if (status === 204) {
                    return data
                } else {
                    throw `Set my password error, status:${response.status}, ${response.data}`
                }
            } catch (err) {
                throw err
            }
        },
        // PASSWORD_RESET_REQUEST send password reset request
        async PASSWORD_RESET_REQUEST(context, payload) {
            try {
                const {status, data, response} = await api.auth.password_reset_request(payload)
                if (status === 200) {
                    return data
                } else {
                    throw `Set my password error, status:${response.status}, ${response.data}`
                }
            } catch (err) {
                throw err
            }
        }




    },
    mutations: {
        AUTH_REQUEST: (state) => {
            state.authStatus = 'loading'
        },
        AUTH_SUCCESS: (state, token) => {
            console.log('update_token')
            state.token = token
            state.authStatus = 'success'
        },
        AUTH_ERROR: (state) => {
            state.authStatus = 'error'
        },
        AUTH_LOGOUT: (state) => {
            state.authStatus = ''
            state.token = ''
        },
        SET_HEADER_AUTH: (state) => {
            api.setHeaderAuth(state.token)
        },
        SET_USER: (state, profile) => {
            state.me = {...profile}
        },
        SET_REDIRECT_AFTER_LOGIN: (state, path) => {
            state.pathAfterLogin = path
        },
        SET_APP_OPTIONS: (state, opt) => (state.appOptions = {...opt})
        // SET_JWT_REFRESHER: (state, id) => {
        //   state.timer = id
        // },
        // STOP_JWT_REFRESHER: (state) => {
        //   console.log('StopRefresher')
        //   if (!state.timer != null) {
        //     clearInterval(state.timer)
        //     state.timer = undefined
        //   }
        // }
    },
    getters: {
        hasToken: (state) => Boolean(state.token),
        profileLoaded: (state) => Object.keys(state.me).length > 0,
        appOptionsLoaded: (state) => Object.keys(state.appOptions).length > 0
    }
}
