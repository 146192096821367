/** ------------ WEB-GIS ------------------- */

import {paramsSerializer, type_json} from '@/api/utils'

export default Api => ({
  /**
   * load gis data
   * webgis/data?lon_max=-61.05&lat_max=-61.4&lon_min=13.95&lat_min=14&zoom=10&var={iri, roads, traffic}&hash=brown
   * @param {integer} zoom
   * @param {float} lat_min
   * @param {float} lat_max
   * @param {float} lon_min
   * @param {float} lon_max
   * @param {float} type
   *
   * @return {Promise<*> []} - 200    Default Response
   * {
    "hash": "brown",
    "data": [
        {
          "road": "A10",
          "values": [
              {
                "start": 0,
                "end": 20,
                "lat": 13.7297927736808,
                "lon": -60.9515961042504,
                "iri": 10.2
              }
          ]
        }
      ]
    }
   * @throws Error
   */
  async load_gis_data(
    {
      zoom,
      lat_min,
      lat_max,
      lon_min,
      lon_max,
      hash,
      type,
      road,
      types,
      filter_by,
      filter_values
    }
  ) {
    const params = {
      zoom,
      lat_min,
      lat_max,
      lon_min,
      lon_max,
      hash,
      var: type,
      all_vars: types.join(','),
      road
    }
    if (filter_by) {
      params.filter_by = filter_by
      params.filter_values = filter_values
    }
    const res = await Api.get(
      `/webgis/data`,
      {
        params,
        paramsSerializer
      },
      {
        headers: {
          ...type_json
        }
      }
    )
    return res
  },

  /**
   * load asset list by type
   * webgis/bridges/iri
   * @param {String} type
   * @return {Promise<*> []} - 200    Default Response
   * {
    "roads": [
        {
            "roadcode": "A10",
            "road_name": "Vieux Fort to SoufriÃ¨re",
            "iri": 4.8
        }
      ]
    }
   * @throws Error
   */
  async load_asset_list_by_type({type}) {
    const res = await Api.get(`/webgis/assets/${type}`, {
      headers: {
        ...type_json
      }
    })

    return res
  },

  /**
   * load map default params
   * @return {Promise<*> {}} - 200    Default Response*
   * @return {Object}
   * @return {string} view_projection: "EPSG:3857",
   * @return {string} data_projection: "EPSG:4326",
   * @return {Array<number, number>} map_centre
   * @return {number} zoom: 11,
   * @return {number} max_zoom: 18,
   * @return {boolean} additional_layers: true
   * @throws Error
   */
  async load_default_params() {
    return await Api.get(`/webgis/default_parameters`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * load vector types
   * webgis/layers
   * @return {Promise<*> []} - 200    Default Response
   * {
    "layers": [
        {
          "key": "iri",
          "value": "map_layers.iri"
        }
      ]
    }
   * @throws Error
   */
  async load_vector_types(domain) {
    return await Api.get(`/webgis/layers`, {
      params: {domain},
      headers: {
        ...type_json
      }
    })
  },

  /**
   * load addition vector types
   * /webgis/additional_layers
   * @return {Promise<*> []} - 200    Default Response
   * {
      "layers": [
        {
          "key": "bli_points",
          "value": "map_layers.bli_points"
        }
      ]
    }
   * @throws Error
   */
  async load_addition_vector_types() {
    const res = await Api.get(`/webgis/additional_layers`, {
      headers: {
        ...type_json
      }
    })

    return res
  },

  /**
   * Load vector filters
   * @param {String} layer
   * @return {} - 200    Default Response
   */
  async load_vector_filters({layer}) {
    const params = {layer}
    return Api.get(
      `/webgis/filters`,
      {params},
      {
        headers: {
          ...type_json
        }
      }
    )
  },

  /**
   * load basemaps
   * webgis/basemaps
   * @return {Promise<*> []} - 200    Default Response
   * {
    "basemap_tiles": [
        {
            "category": "osm",
            "flavors": [
                {
                    "name": "osm",
                    "props": {
                        "crossOrigin": "anonymous",
                        "wrapX": false
                    }
                }
            ]
        }
      ]
    }
   * @throws Error
   */
  async load_basemaps(domain) {
    return await Api.get(`/webgis/basemaps`, {
      headers: {
        ...type_json
      }
    })
  }
})
