import api from '@/api'

export default {
  state: {},
  actions: {
    // LOAD_USERS loads user list
    async LOAD_USERS() {
      try {
        const {status, data} = await api.users.load_users()
        if (status === 200) {
          return data
        } else {
          throw `Load user list error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    // LOAD_USER_BY_LOGIN loads user props
    async LOAD_USER_BY_LOGIN(context, login) {
      try {
        const {status, data} = await api.users.load_user_by_login(login)
        if (status === 200) {
          return data
        } else {
          throw `Load roles list error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    // LOAD_ROLES loads roles list
    async LOAD_ROLES() {
      try {
        const {status, data} = await api.permissions.load_roles()
        if (status === 200) {
          return data
        } else {
          throw `Load roles list error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },

    // ADD_USER adds a new user
    async ADD_USER(context, payload) {
      try {
        const {status = 0, response} = await api.users.add(payload)
        if (status === 201) {
          return 'added'
        } else {
          throw `Add user error, status: ${response.status}, ${response.data}`
        }
      } catch (err) {
        throw err
      }
    },

    // UPD_USER  updates a user
    async UPD_USER(context, payload) {
      const {login, data} = payload
      const {status = 0, response} = await api.users.update(login, data)
      try {
        if (status === 204) {
          return 'updated'
        } else {
          throw `Upd user error, status: ${response.status}, ${response.data}`
        }
      } catch (err) {
        throw err
      }
    },

    // DELETE_USER  deletes user
    async DELETE_USER(context, login) {
      const {status, response} = await api.users.delete(login)
      try {
        if (status === 200) {
          return 'deleted'
        } else {
          throw `Delete user error, status: ${response.status}, ${response.data}`
        }
      } catch (err) {
        throw err
      }
    },

    // LOAD_ROLES loads roles list
    async LOAD_ROLE_USERS(context, role_name) {
      try {
        const {status, data} = await api.permissions.load_role_users(role_name)
        if (status === 200) {
          return data
        } else {
          throw `Load role users error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    // LOAD_PERMISSIONS loads permissions list
    async LOAD_PERMISSIONS() {
      try {
        const {status, data} = await api.permissions.load_list()
        if (status === 200) {
          return data
        } else {
          throw `Load permissions list error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    // GRANT_PERMISSION grants user role permission
    async GRANT_PERMISSION(context, payload) {
      try {
        const {status, data} = await api.permissions.grant(payload)
        if (status === 200) {
          return data
        } else {
          throw `Grant user role permission error, code:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    // REVOKE_PERMISSION revokes user role permission
    async REVOKE_PERMISSION(context, payload) {
      try {
        const {status, data} = await api.permissions.revoke(payload)
        if (status === 200) {
          return data
        } else {
          throw `Revoke user role permission error, code:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async SET_USER_PASSWORD(context, payload) {
      try {
        const {status, data, response} = await api.users.set_password(payload)
        if (status === 204) {
          return data
        } else {
          throw `Set user password error, status:${response.status}, ${response.data}`
        }
      } catch (err) {
        throw err
      }
    }
  },
  mutations: {},
  getters: {}
}