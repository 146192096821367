import {type_json} from '@/api/utils'

/** ------------ FILES ------------------- */

export default Api => ({
  /**
   /**
   * upload inspection file
   * @param formdata
   * @return {Promise<*>}
   * @throws Error
   */
  async add_inspection_file(formData) {
    return Api.post(`/bams/inspection-files/add`, formData, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * load inspection files
   * @param
   * @return {Promise<*>}
   * @throws Error
   */
  async load_inspection_files(payload) {
    const query = Object.entries(payload).map(x => x.join('=')).join('&')
    return Api.get(`/bams/inspection-files/list?${query}`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * load inspection list
   * @param {Object}payload
   * @param {string} asset_type [bridge, tunnel]
   * @param {string} asset_id
   * @param {string} inspection_type [condition, inventory]
   * @return {Promise<*>}
   * @throws Error
   */
  async load_bms_inspections(payload) {
    const {asset_type, asset_id, inspection_type} = payload
    return Api.get(`/bams/${asset_type}s/${asset_id}/${inspection_type}/list`, {
      headers: {
        ...type_json
      }
    })
  }
})