import api from '@/api'

export default {
    state: {tab_data: []},
    actions: {
        async LOAD_DASHBOARD_TABS(context, system) {
            try {
                const {status, data} = await api.dashboard.load_tabs(system)
                if (status === 200) {
                    return data
                } else {
                    throw 'Load dashboard tabs error'
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_DASHBOARD_TAB_DATA({commit}, {system, tab_id}) {
            try {
                const {status, data} = await api.dashboard.load_tab_data(system, tab_id)
                if (status === 200) {
                    commit('SET_DASHBOARD_TAB_DATA', data)
                } else {
                    throw `Load dashboard tab data status ${status}`
                }
            } catch (err) {
                throw `Load dashboard: ${err}`
            }
        }
    },
    mutations: {
        SET_DASHBOARD_TAB_DATA(state, data) {
            state.tab_data = [...data]
        }
    },
    getters: {}
}
