/**---------  LOCALISATION ---------------*/

import {type_json} from '@/api/utils'
export default Api => ({
  /**
   * Languages list
   * @returns {Object} languages properties
   */
  load_locales() {
    return Api.get(`/localisation/languages`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * Login form interface translate strings
   * @return {Promise<*> {item}}
   * category: {
   *  key: string
   * } - 200    Default Response
   * @throws Error
   */
  load_login_messages(lang) {
    return Api.get(`/localisation/login_strings/${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * User interface translate strings
   * @return {Promise<*> {item}}
   * category: {
   *  key: string
   * } - 200    Default Response
   * @throws Error
   */
  load_ui_messages(lang) {
    return Api.get(`/localisation/${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * list of all translating keys
   * @returns {Promise<*> [item]}
   * @returns {Object} item
   * @returns "id": number,
   * @returns "category": string,
   * @returns "key": string,
   * @returns "en": string,
   * @returns "fr": string,
   * @throws Error
   */
  load_translate() {
    return Api.get(`/localisation/list`, {
      headers: {
        ...type_json
      }
    })
  },
  /**
   * add translate entry
   * @param {object} entry
   * {
      "category": "string",
      "key": "string",
      "en": "string",
      "al": "string",
    }
   * @return {Promise<*> {}} - 201    Default Response
   * {
      "id": integer,
      "category": "string",
      "key": "string",
      "en": "string",
      "al": "string",
    }
   * @throws Error
   */
  add_translate_entry(item) {
    return Api.post(
      `/localisation/`,
      {...item},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /**
   * upd translate entry
   * @param {integer} id
   * @param {object} entry
   * {
      "category": "string",
      "key": "string",
      "en": "string",
      "fr": "string",
    }
   * @return {Promise<*> []} - 201    Default Response
   * @throws Error
   */
  upd_translate_entry(item) {
    return Api.put(
      `/localisation/`,
      {...item},
      {
        headers: {
          ...type_json
        }
      }
    )
  }
})