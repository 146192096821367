import api from '@/api'

export default {
    state: {},
    actions: {
        async LOAD_PLAN_LIST() {
            try {
                const res = await api.plans.load_plan_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load Plan list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async GET_PLAN_BY_ID(context, plan_id) {
            try {
                const res = await api.plans.load_plan_by_id(plan_id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load Plan by id error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_PLAN_WORKS(context, plan_id) {
            try {
                const res = await api.plans.load_plan_works(plan_id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load Plan works error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_PLAN(context, plan) {
            try {
                const {status} = await api.plans.add_plan_item(plan)
                if (status === 201) {
                    return true
                } else {
                    throw `Add Plan error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async UPD_PLAN(context, payload) {
            try {
                const {plan_id, data} = payload
                const {status} = await api.plans.upd_plan_item(plan_id, data)
                if (status === 200) {
                    return true
                } else {
                    throw `Update Plan error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async PLANS_CHANGE_WORK_SELECTED(context, payload) {
            try {
                const {plan_id, data} = payload
                const {status} = await api.plans.change_plan_work_selected(plan_id, data)
                if (status === 200) {
                    return true
                } else {
                    throw `Update Plan works selected  error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },

    },
    mutations: {}
}
